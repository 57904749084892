











import { Component, Vue } from "vue-property-decorator";
import Header from "@/components/Header.vue";
import Concept from "@/components/Concept.vue";
import Business from "@/components/Business.vue";
import Company from "@/components/Company.vue";
import Inquiry from "@/components/Inquiry.vue";
import Footer from "@/components/Footer.vue";

@Component({
  components: {
    Header,
    Concept,
    Business,
    Company,
    Inquiry,
    Footer
  },
})
export default class App extends Vue {}
