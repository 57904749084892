import Vue from "vue";
import App from "./App.vue";
import BootstrapVue from "bootstrap-vue";
import "bootstrap/dist/css/bootstrap.css";
import "bootstrap-vue/dist/bootstrap-vue.css";
import VueFormGenerator from "vue-form-generator";
import "vue-form-generator/dist/vfg.css";

Vue.use(BootstrapVue);
Vue.use(VueFormGenerator);
VueFormGenerator.validators.resources.fieldIsRequired = "必須項目です";
VueFormGenerator.validators.resources.textTooBig = "{1}文字以内で入力してください";
VueFormGenerator.validators.resources.invalidEmail = "メールアドレスを入力してください";

Vue.config.productionTip = false;
new Vue({
    render: (h) => h(App),
}).$mount("#app");
