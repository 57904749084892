import { render, staticRenderFns } from "./Business.vue?vue&type=template&id=cb1e4d58&scoped=true&"
var script = {}
import style0 from "./Business.vue?vue&type=style&index=0&id=cb1e4d58&scoped=true&lang=scss&"


/* normalize component */
import normalizer from "!../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "cb1e4d58",
  null
  
)

export default component.exports