


















import { Component, Vue } from "vue-property-decorator";
import Email from '@/lib/smtp.js';

@Component
export default class Inquiry extends Vue {
    schema = {
        fields: [
            {
                type: 'input',
                inputType: 'text',
                label: 'お名前',
                placeholder: '50文字以内、入力必須',
                model: 'name',
                required: true,
                max: 50,
                validator: ["string"],
            },
            {
                type: 'select',
                label: '性別',
                model: 'gender',
                values: ['男性', '女性', 'その他'],
            },
            {
                type: 'input',
                inputType: 'email',
                label: 'メールアドレス',
                placeholder: '入力必須',
                model: 'email',
                required: true,
                max: 300,
                validator: ["email"],
            },
            {
                type: 'input',
                inputType: 'text',
                label: '件名',
                placeholder: '100文字以内',
                model: 'title',
                max: 100,
                validator: ["string"],
            },
            {
                type: 'textArea',
                label: 'お問い合わせ内容',
                placeholder: '2000文字以内、入力必須',
                model: 'content',
                required: true,
                max: 2000,
                validator: ["string"],
                rows: 10,
            },
        ]
    };
    model = {
        name: '',
        gender: '',
        email: '',
        title: '',
        content: '',
    };
    formOptions = {
        validateAfterLoad: false,
        validateAfterChanged: true,
        validateAsync: true
    };
    
    mounted() {
        const loader: HTMLElement = (document.getElementById("loader") as HTMLElement);
        if (loader.style != undefined) {
            loader.style.display = "none";
        }
    }

    onValidated(isValid: boolean, errors: any[]) {
        const sendButton: HTMLButtonElement = (document.getElementById("send") as HTMLButtonElement);
        const isNameEmpty = this.model.name == undefined || this.model.name == '';
        const isEmailEmpty = this.model.email == undefined || this.model.email == '';
        const isContentEmpty = this.model.content == undefined || this.model.content == '';
        if (isValid && !isNameEmpty && !isEmailEmpty && !isContentEmpty) {
            sendButton.removeAttribute("disabled");
        } else {
            sendButton.setAttribute("disabled", "disabled");
        }
    }

    send() {
        const sendButton: HTMLButtonElement = (document.getElementById("send") as HTMLButtonElement);
        if (sendButton.style != undefined) {
            sendButton.style.display = "none";
        }

        const loader: HTMLElement = (document.getElementById("loader") as HTMLElement);
        if (loader.style != undefined) {
            loader.style.display = "block";
        }

        Email.send({
            Host : process.env.VUE_APP_SMTP_HOST,
            Username : process.env.VUE_APP_SMTP_USER,
            Password : process.env.VUE_APP_SMTP_PASS,
            To : process.env.VUE_APP_TO_ADDRESS,
            From : process.env.VUE_APP_FROM_ADDRESS,
            Subject : `${this.model.name} 様よりお問い合わせがありました`,
            Body : this.createFormatedMailText()
        }).then(() => {
            alert(
                `メールが送信されました。`
            )
            if (loader.style != undefined) {
                loader.style.display = "none";
            }
            if (sendButton.style != undefined) {
                sendButton.style.display = "block";
            }
        }).catch(() => {
            alert(
                `メール送信でエラーが発生しました。
                再度送信をお試しください。`
            )
            if (loader.style != undefined) {
                loader.style.display = "none";
            }
            if (sendButton.style != undefined) {
                sendButton.style.display = "block";
            }
        })
    }

    createFormatedMailText(): string {
        return `
            ========================================================================================<br />
            ${this.model.name} 様（性別：${this.model.gender}）より以下の内容でお問い合わせがありました。<br />
            ========================================================================================<br />
            <br />
            お客様メールアドレス：${this.model.email}<br />
            <br />
            件名：${this.model.title}<br />
            <br />
            お問い合わせ内容：<br />
            <pre>${this.model.content}</pre><br />
        `;
    }
}
